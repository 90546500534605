<template>
  <Header></Header>
  <div class="b1">
    <div class="space3"></div>
    <div class="b2">
      <InfoNav :pColor1="color1" pColor2="#ddd" :pFont1="font1" :pFont2="font2" :pFont3="font3" :pFont4="font4" :pTabs="tabs" :pTitles="titles" :pSubTitles="subTitles" :pData="data" pSelected="promiseLand"></InfoNav>
    </div>
    <div class="space3"></div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '../HeaderView.vue';
import Footer from '../FooterView.vue';
import InfoNav from '../../Utilities/InfoNav.vue';

export default {
  name: 'resources',
  components: {    
    Header,
    Footer,
    InfoNav,
  },
  computed: {
    font1() {
      return {
        fontFamily: 'font1',
        color: 'black',
        fontSize: 'calc(1.2rem) !important',
      }
    },
    font2() {
      return {
        fontFamily: 'font1',
        color: 'black',
        fontSize: 'calc(1.8rem) !important',
      }
    },
    font3() {
      return {
        fontFamily: 'font2',
        // color: 'black',
        fontSize: 'calc(1.2rem) !important',
        lineHeight: 'calc(1.2rem * 1.5) !important',
      }
    },
    font4() {
      return {
        fontFamily: 'font1',
        fontStyle: 'italic',
      }
    },
    tabs() { 
      return {
        promiseLand: 'Promise Land Community',
        moneysavers: 'Money Savers',
        resourceForFamilies: 'Resources for families',
        ableAccount: 'Able Account',
        theArc: 'The Arc',
        taxRelief: 'Tax Relief',
        legalAssistance: 'Indiana Legal Services',
        insource: 'Special Education Parent Support',
        awsFoundation: 'AWS Foundation',
        inesa: 'Indiana Educational Scholarship Accounts',
        aKidAgain: 'A Kid Again',
        wentzFoundation: 'Carson Wentz Foundation',
        lifeCourse: 'Charting the LifeCourse',
      }
    },
    titles() {
      return {
        promiseLand: 'Promise Land Community',
        moneysavers: 'Money Savers for those with Disabilities or Recipients of Government Assistance',
        resourceForFamilies: 'Resources for families',     
        ableAccount: 'New Investable Indiana helps individuals save, while preserving their SSI and Medicaid',   
        theArc: 'Guardianship & Alternatives to Guardianship',
        taxRelief: 'Tax relief for families who live with an individual',
        legalAssistance: 'Indiana Legal Services',
        insource: 'Insource',
        awsFoundation: 'AWS Foundation',
        inesa: 'Indiana Educational Scholarship Accounts',
        aKidAgain: 'A Kid Again',
        wentzFoundation: 'Carson Wentz Foundation',
        lifeCourse: 'Charting the LifeCourse',
      }
    },
    subTitles() {
      return {
        promiseLand: '',
        moneysavers: 'Updated 4/25/23. Programs subject to change. Contact individual organizations to learn more.<br>NOTE: To receive documentation regarding your government assistance, visit <a href="https://fssabenefits.in.gov/bp/#/" target="_blank">fssabenefits</a>.',        
        resourceForFamilies: '',
        ableAccount: '',
        theArc: 'The Arc Master Trust',
        taxRelief: '',
        legalAssistance: 'Equal Access To Justice',
        insource: 'Special Education Parent Support',
        awsFoundation: '',
        inesa: '',
        aKidAgain: 'Enjoy stress-free Adventures with your family',
        wentzFoundation: '',
        lifeCourse: 'Introduction to the LifeCourse Framework',
      }
    },
    data() {
      return {
        promiseLand: '<div><a href="https://promiselandcommunity.com/home" target="_blank">https://promiselandcommunity.com</a></div>',
        moneysavers: '<div><span style="font-weight:500; font-family:font1">Amazon</span> - Receive a 50% discount on Prime Memberships for qualifying government assistance recipients. Visit <a href="https://www.amazon.com/58f8026f-0658-47d0-9752-f6fa2c69b2e2/qualify" target="_blank">amazon.com/qualify</a> to sign up.</div>\n' +
                     '<div><span style="font-weight:500; font-family:font1">The Affordable Connectivity Program</span> - Government assistance recipients can receive a discount on their home internet service. The recipient of the assistance just needs to be a member of the household and they don\'t need to be the one who has the account in their name. Enroll here: <a href="https://www.fcc.gov/acp" target="_blank">fcc.gov/acp</a></div>\n' +
                     '<div><span style="font-weight:500; font-family:font1">Access Pass</span> - Gain $2 admission to local museums (excluding the Zoo) and discounts on select annual events such as Headless Horseman and Winter Lights. Discount admission to local museums. Sign up in person at any participating museum or online at <a href="https://www.childrensmuseum.org/visit/access-pass" target="_blank">childrenmuseum.org/visit/access-pass</a></div>\n' +
                     '<div><span style="font-weight:500; font-family:font1">National Parks & Federal Recreational Lands Access Pass</span> - Citizens or permanent residents of the United States that have been medically determined to have a permanent disability can receive a free lifetime pass that provides admittance to more than 2,000 recreation sites managed by five Federal agencies. At many sites the Access Pass provides the pass owner a discount on Expanded Amenity Fees (such as camping, swimming, boat launching, and guided tours). Visit <a href="https://store.usgs.gov/access-pass" target="_blank">store.usgs.gov/access-pass</a></div>\n' +
                     '<div><span style="font-weight:500; font-family:font1">United Healthcare Children\'s Foundation</span> - offers grants for medical expenses not covered by insurance. The family income level makes it attainable for most middle class families and the application process is pretty easy. Visit <a href="https://www.uhccf.org/" target="_blank">uhccf.org</a></div>\n' +
                     '<div><span style="font-weight:500; font-family:font1">Grants for Families with Developmental Disabilities</span><br><a href="https://www.autismspeaks.org/autism-grants-families" target="_blank">autismspeaks.org/autism-grants-families</a><br><a href="https://www.iidc.indiana.edu/irca/articles/grant-funding-opportunities.html" target="_blank">iidc.indiana.edu</a><br><a href="https://www.grantsformedical.com/private-grants-for-individuals-with-disabilities.html" target="_blank">grantsformedical.com</a></div>',        
        resourceForFamilies: '<div><span style="font-weight:500; font-family:font1">Responsibilities of a Special Needs Trust Trustee</span><br><a style="text-overflow:ellipsis; white-space: nowrap; overflow: hidden; display: block" href="https://attorney.elderlawanswers.com/newsletter/actions/view-article-new/c/42993/id/6380" target="_blank">attorney.elderlawanswers.com/newsletter/6380</a></div>\n' +
                             '<div><span style="font-weight:500; font-family:font1">Loans and Grants for Wheelchair Vans and Vehicle Adaptations</span><br><a style="text-overflow:ellipsis; white-space: nowrap; overflow: hidden; display: block" href="https://attorney.elderlawanswers.com/newsletter/actions/view-article-new/c/42993/id/12325" target="_blank">attorney.elderlawanswers.com/newsletter/12325</a></div>\n' +
                             '<div><span style="font-weight:500; font-family:font1">Consequences of Not Using a Medicare Set-Aside Account</span><br><a style="text-overflow:ellipsis; white-space: nowrap; overflow: hidden; display: block" href="https://attorney.elderlawanswers.com/newsletter/actions/view-article-new/c/42993/id/12335" target="_blank">attorney.elderlawanswers.com/newsletter/12335</a></div>\n' +
                             '<div><span style="font-weight:500; font-family:font1">What You Need to Know About Applying for SSDI or SSI</span><br><a style="text-overflow:ellipsis; white-space: nowrap; overflow: hidden; display: block" href="https://attorney.elderlawanswers.com/newsletter/actions/view-article-new/c/42993/id/4457" target="_blank">attorney.elderlawanswers.com/newsletter/4457</a></div>',
        ableAccount: '<div><a href="https://savewithable.com/in/home.html" target="_blank">savewithable.com</a></div>',
        theArc: '<div><a href="./Guardianship0002.pdf" target="_blank">guardianship.pdf</a></div>',
        taxRelief: '<div><a href="./TaxRelief.pdf" target="_blank">TaxRelief.pdf</a></div>',
        legalAssistance: '<div>Apply for Free Legal Assistance<br><a href="https://www.indianalegalservices.org/applyonline/" target="_blank">indianalegalservices.org</a></div>',
        insource: '<div><a href="https://insource.org/" target="_blank">https://insource.org</a></div>',
        awsFoundation: '<div>Helping children and adults with enduring intellectual, developmental and physical disabilities live as independently as possible, be included in the community and function at their highest potential.\n<a href="https://www.awsfoundation.org/" target="_blank">https://www.awsfoundation.org/</a></div>',
        inesa: '<div>Indiana Educational Scholarship Accounts are back for the 2024-2025 school year!\nINESA provides funding to students with disabilities, allowing parents to customize their child’s education to meet their unique individual needs. INESA funds may be used for private-school education, tutoring, therapies, camp and additional in-person educational expenses.<br><br>\n' +
        '<span style="font-weight:500; font-family:font1">The application period for INESA begins March 1.</span>\n'+
        'Eligible students must meet these requirements: <ul><li>Students must be Indiana residents.</li><li>Students must be K-12 grade level and between five and 22 years of age (by August 1, 2024).</li><li>Students must have a disability and require special education.</li><li>Households must meet income eligibility (annual income no more than 400% of the amount to qualify for the federal free or reduced-price lunch).</li><li>Students cannot be enrolled in public or charter schools.</li></ul><br><br>' +
        '<span style="font-weight:500; font-family:font1">IMPORTANT: If your student is participating in INESA for the 2023-2024 (current) school year, you will need to reapply for funding for the 2024-2025 school year.</span><br><br>For help, questions or concerns, contact INESA staff at 317.232.0723 or <a href="mailto:esa@tos.in.gov" target="_blank">esa@tos.in.gov</a><br><br>For more information on how to apply for INESA, <a href="https://r20.rs6.net/tn.jsp?f=001NB5QEF3GBrbPGtJZSEVe88l58-M59c7KjWX6uesm0NepaEyuYu7vdsDAfJxBHFK0Ru64h7F7Hn6bwwApWIdLAALL04mmnGR1ndO0g0qoSJeImQc3SiUi_sU6Ji7hmcXTYUkpf7DzrpESlJ_3_2mP2kZrpFN5O7wE&c=a-7XVcO7UQM1Ck83TFOTq5jL8GqMKUzj2iqo1fLCsOiRwwgzwJFg8g==&ch=T8dKiPA5jkuX3FSoGY3Yfq-gyUxp6Pau6XEXZaPXPNDbzFp23y6cBg==" target="_blank">click here</a>. If you’re new to INESA, please see our <a href="https://r20.rs6.net/tn.jsp?f=001NB5QEF3GBrbPGtJZSEVe88l58-M59c7KjWX6uesm0NepaEyuYu7vdiWbPUqj4iivbpyfYBqSnM7vac9tBpS5cshyYmmcL-I5lV1KCf5j7nv51nRWM9jlFQe6Uo2lOCAuWZpWAgg6lwJnzZ7l1qNLOG9XiEISmX1531AOcY1oheGyJ9WrDORYxg==&c=a-7XVcO7UQM1Ck83TFOTq5jL8GqMKUzj2iqo1fLCsOiRwwgzwJFg8g==&ch=T8dKiPA5jkuX3FSoGY3Yfq-gyUxp6Pau6XEXZaPXPNDbzFp23y6cBg==" target="_blank">Parents page on the INESA website</a> for answers to frequently asked questions.</div>',
        aKidAgain: '<div>A Kid Again offers free local events and activities called Adventures for families who have a child facing a life-threatening condition. We handle all expenses and details, so you can simply enjoy quality time with your loved ones.\n<a href="https://akidagain.org/" target="_blank">https://akidagain.org/</a> </div>',
        wentzFoundation: '<span style="font-weight:500; font-family:font1"><a href="https://www.ao1foundation.org/" target="_blank">https://www.ao1foundation.org</a><span><br><br>\n' +
        '<span style="font-weight:500; font-family:font1"><a href="https://www.ao1foundation.org/thy-kingdom-crumb/" target="_blank">Thy Kindom Crumb</a></span> - Thy Kingdom Crumb (TKC) is a mobile food truck that exists to "Demonstrate the love of God and to infuse His hope by feeding people and uplifting communities." Through the distribution of free, high-quality food with excellence and community outreach, TKC aims to help people tangibly experience the unconditional love of God.<br><br>\n' +
        '<span style="font-weight:500; font-family:font1"><a href="https://www.ao1foundation.org/haiti-sports-complex/" target="_blank">Haiti Sports Complex</a></span> - The Haiti Sports Complex is “A partnership to bring hope to a nation through sport.” This facility, which includes an 18,750 square-foot multipurpose building and a full-size turf soccer field, impacts 15,000 children per year by providing them with an opportunity to develop physical abilities and grow in responsibility.<br><br>\n' +
        '<span style="font-weight:500; font-family:font1"><a href="https://www.ao1foundation.org/outdoor-ministry/" target="_blank">Outdoor Ministry</a></span> - The Outdoor Ministry aims to serve children with life-threatening illnesses or other life-altering medical situations in order to “Foster a genuine passion for outdoor activities and bring people closer to God through discipleship.” This is accomplished through two programs: <a href="https://www.ao1foundation.org/mountain-movers" target="_blank">Mountain Movers</a> and <a href="https://www.ao1foundation.org/camp-conquerors" target="_blank">Camp Conquerors</a>',
        lifeCourse: '<iframe style="position: relative; aspect-ratio: 16/9; height: auto; width: 80%; padding-left:10%;" src="https://www.youtube.com/embed/hWrGklhlKgA?si=MbqCgtDakCxqUelL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
      }
    },
  },
  created() {
    window.scrollTo({
      top:0,
      left:0,
      behavior: 'smooth'
    });
  },  
}
</script>

<style scoped>
.b1 {    
  width:100%;
  /* background-image: url("../../assets/resources/background8.jpg"); */  
  background-image: url("../../assets/background/bg22.jpg");
  background-size:cover;   
  background-position: center;
}

.b2 {  
  width:80%;
  margin-left:10%;
}
.space3 {
  width:100%;
  height:200px;
}
@media only screen and (max-width: 1152px) {
  .b2{
    width:92%;
    margin-left:4%;
  }
  .space3{
    height:120px;
  }
  
}
</style>