import {createRouter, createWebHistory} from 'vue-router';
import Home from './components/pages/Home.vue';
import About from './components/pages/About.vue';
import Services from './components/pages/Services.vue';
import Resources from './components/pages/Resources.vue';
import SignUp from './components/pages/SignUp.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
      { path: '/',redirect: '/home'},
      { path: '/home', name: 'home', component: Home},
      { path: '/about', name: 'about', component: About},
      { path: '/services', name: 'services', component: Services},
      { path: '/resources', name: 'resources', component: Resources},
      { path: '/signup', name: 'signup', component: SignUp},
  ],

});


export default router;