// Color2: #757263
// Color3: #e1ccbe

// Font-Family: granville H1
// Font-Family: brandon-grotesque H2
import router from './router';

export default {
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    color1() {
      return "#eeedeb";
    },
    color2() {
      return "#757263";
    },
    color21() {
      return "#8c8977";
    },
    color3() {
      return "#e1ccbe";
    },
    color31() {
      return "#e1d2be";
    },
    color4() {
      return "#9c9c9c";
    },
    color5() {
      return "#d9d7d4";
    },
    color6() {
      return "#c2c1c0"
    },
    color7() {
      return '#F8F8F8'
    },
    font1() {
      return "granville";
    }, 
    font2() {
      return "brandon-grotesque";
    },
    margin1() {            
      return this.isMobile ? "30px" : "75px";
    }
  },
  methods: {
    openLink(url){
      window.open(url,'_blank','noreferrer');
    },
    dispatch(name, name2='', params='{}'){      
      window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: name2 !=  ''?name2:name }}));
      router.replace({name: name, params: params});
    },
    windowSize(){
      // console.log(window.innerWidth);
      if(window.innerWidth<=1152){
          this.isMobile=true;
      } else {
          this.isMobile=false;
      }      
      console.log("isMobile: " + this.isMobile);      
    },
    backgroundClick(){
      console.log("backgroundClick");
      this.closeMenu();
    }
  },
  
  created() {
    this.windowSize();
  }
}