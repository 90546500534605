<template>
  <div class="b1">
    <div class="b2">
      <!-- <img class="img1" :src="require('../../assets/About/header1.jpg')" /> -->
      <div class="b3">
        <div :class="['t5','b4']">
          <div v-if="!isMobile" style="height:10px;width:100%;"></div>
          <div v-if="isMobile" style="height:35px;width:100%;"></div>
          <div class="b6">Come learn about who makes up Full Potential Inc. Get familiar with the friendly faces of our staff and learn about the history of how Full Potential grew into the community it is now.</div>
        </div>
      </div>
      <Header></Header>    

      <div class="b5" v-if="!isMobile" >
        <div class="t5">Our Staff</div>
        <div class="t3">Meet the staff who make up Full Potential Inc!</div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff1.jpg" />
            <div class="t4">Cynthia Church</div>
            <div class="t6">Owner & President</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff5.png" />
            <div class="t4">Elizabeth Church</div>
            <div class="t6">Vice President</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff6.png" />
            <div class="t4">Kim Landis</div>
            <div class="t6">HR Director</div>
          </div>  
        </div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff7.jpg" />
            <div class="t4">Julia Sieloff</div>
            <div class="t6">Office Administration</div>
          </div>        
          <div class="col1">
            <img class="img1" src="../../assets/About/staff2.png" />
            <div class="t4">Kris Streeter RN</div>
            <div class="t6">Registered Nurse</div>
          </div>  
          <div class="col1">
            <img class="img1" src="../../assets/About/staff9.jpg" />
            <div class="t4">Charity Walker</div>
            <div class="t6">Administration Assistant</div>
          </div>
        </div>
        <div class="row1">          
          <div class="col1">
            <img class="img1" src="../../assets/About/staff10.jpg" />
            <div class="t4">Morgan Walker</div>
            <div class="t6">Billing Specialist</div>
          </div>
        </div>
      </div>
      <div class="b5" v-if="isMobile" >
        <div class="t5">Our Staff</div>
        <div class="t3">Meet the staff who make up Full Potential Inc!</div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff1.jpg" />
            <div class="t4">Cynthia Church</div>
            <div class="t6">Owner & President</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff5.png" />
            <div class="t4">Elizabeth Church</div>
            <div class="t6">Vice President</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff6.png" />
            <div class="t4">Kim Landis</div>
            <div class="t6">HR Director</div>
          </div>  
          <div class="col1">
            <img class="img1" src="../../assets/About/staff7.jpg" />
            <div class="t4">Julia Sieloff</div>
            <div class="t6">Office Administration</div>
          </div>   
        </div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff2.png" />
            <div class="t4">Kris Streeter RN</div>
            <div class="t6">Registered Nurse</div>
          </div>  
          <div class="col1">
            <img class="img1" src="../../assets/About/staff9.jpg" />
            <div class="t4">Charity Walker</div>
            <div class="t6">Administration Assistant</div>
          </div>
        </div>
        <div class="row1">          
          <div class="col1">
            <img class="img1" src="../../assets/About/staff10.jpg" />
            <div class="t4">Morgan Walker</div>
            <div class="t6">Billing Specialist</div>
          </div>
        </div>
      </div>
      <div :class="['b7','b72']">
        <div class="b71">
          <div class="t5">Our History</div>
          <div class="b8">          
            <div :class="['b9','b91']">
              <div class="t4">Cynthia Church's family business, Full Potential Inc., is inspired by her son's diagnosis of Autism and moderate mental disabilities. Today Cynthia works as a legal Medicaid Waiver Provider to provide services for participants.</div>
              <div style="height:30px; width:100%" v-if="isMobile"></div>
              <div class="t4">Cynthia continues to extend her hand of encouragement to families struggling with disabilities. She longs to help all of her clients succeed and meet their Full Potential.</div>
            </div>
            <div :class="['b9','b91']">
              <div class="t4">Cynthia has proven her dedication in sharing her trials and successes while assisting her rural community for over 20 years. She hosts a resource support group as well as a non for profit 501(c)(3) ministry to families with individuals who are diagnosed with disabilities. Cynthia and her husband Mori have been married for 30 years and have two other adult children. They retired as licensed therapeutic foster parents, and they have loved and cared for over 30 other children in their home.</div>
            </div>
          </div>
          <div class="b8">
            <div :class="['b9','b91']" style="height: 100%;">
              <img class="img2" src="../../assets/About/about1.jpg" />
            </div>
            <div style="height:30px; width:100%" v-if="isMobile"></div>
            <div :class="['b9','b92']">
              <carousel />
            </div>
          </div>
        </div>
      </div>
      <div :class="['b7','b73']">
        <div class="b71">
          <div class="t5">Mission Statement</div>
          <div class="b8">          
            <div class="b10" ref="b10">
              <div class="t4">Full Potential is committed to the community of individuals who are walking out life with a disability. Full Potential, Inc. will support the individual and family with dignity, integrity and compassion. Full Potential, Inc. is dedicated to assist the individual to experience life, reaching their full potential.</div>
              <div class="t5" :style="[ isMobile? 'margin-top:50px' : 'margin-top:100px']" >Our Philosophy</div>
              <div class="t4" style="margin-top:30px">Full Potential is committed to:</div>
              <ul class="t4" style="margin-bottom:0">
                <li>Safeguarding intrinsic human rights and dignity</li>
                <li>Decision-making processes that are client centered</li>
                <li>Self-determination</li>
                <li>Community inclusion</li>                
              </ul>
            </div>
            <div class="b11">
              <div class="b111">
                <div :class="['t4','b12']" ref="b12">In fulfilling its mission, Full Potential deems itself successful when its clients make progress towards having choices, access to opportunities, skills and supports to live, learn, work and recreate in the community, with their residences and the broader community.</div>                            
                <div class="b13">
                  <img class="img3" src="../../assets/About/about2.png" />              
                </div>
              </div>
            </div>
          </div>  
          <ul class="t4" :style="[ isMobile? 'margin-top:50px': 'margin-top:0']">
            <li>Personal growth through individually relevant and measurable goals</li>
                <li>Behavior change through the creation of “environments for success and competence”</li>
                <li>Proactive intervention</li>
                <li>Support for the involvement of family and significant others</li>
                <li>Staff with a value base and personality factors consistent with Full Potential’s philosophy</li>
                <li>Focusing on measuring outcomes</li>
          </ul>        
        </div>
      </div>
    </div>        
    
    <Footer></Footer>
  </div>
</template>


<script>
  import Footer from '../FooterView.vue';
  import Header from '../HeaderView.vue';
  import Carousel from '../../Utilities/CarouselAbout.vue';
  export default {
    components: {
      Footer,
      Header,
      Carousel,
    },
    name: 'about',
    data() {
      return {
        b10Height: 0,
        b12Height: 0,
        b13Height: 0,
        b10Watcher: null,
      }
    },
    mounted() {
      window.addEventListener('load',this.handleResize);
      window.addEventListener("resize", this.handleResize);
      // this.b10Watcher = new ResizeObserver(this.handleResize).observe(this.$refs.b10);
    },
    methods: {
      handleResize() {
        console.log('resize');        
        if(this.$refs.b10 === null){
          return;
        }        
        this.b10Height = this.$refs.b10.clientHeight + 'px';
        this.b12Height = this.$refs.b12.clientHeight + 'px';
        this.b13Height = this.$refs.b10.clientHeight - this.$refs.b12.clientHeight + 'px';
      }
    },
    created() {
      this.$nextTick(() => {
        this.handleResize();
      });
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
    }
  }
</script>


<style scoped>
.b1 {
  width:100%;
  /* height: fit-content;     */
  /* color:white; */
}
.b2 {  
  width:100%;
}
.b3 {
  display:block;
  width:100%;
  /* height:auto; */
  /* background-image: url("../../assets/About/header1.jpg"); */
  /* background-image: url("../../assets/background/bg9.jpg"); */
  background-image: url("../../assets/background/bg14.jpg");
  background-size:cover;   
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 16/9;
}
.b4 {
  /* padding-top: 340px; */
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content: space-evenly;
  align-items: space-evenly;
  color: white;
  margin-left: v-bind(margin1);
  margin-right: v-bind(margin1);
}
.b5 {
  padding-top:100px;
  padding-bottom:100px;
  margin-left: v-bind(margin1);
  margin-right: v-bind(margin1);
}
.b7 {    
  width:100%;  
}
.b71 {
  padding-top:100px;
  padding-bottom:100px;
  margin-left: v-bind(margin1);
  margin-right: v-bind(margin1);  
}
.b72 {
  background-color:v-bind(color2);
}
.b73 {
  background-color:v-bind(color3);
  height:fit-content;
}
.b8 {
  position:relative;
  display:flex;
  width:100%;
  justify-content: space-between;
  margin-top:30px;  
  height:fit-content;
}
.b9 {
  width:47%;    
}
.b91 {
  color:v-bind(color1);
}
.b92 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.b10 {  
  width:47%;
  /* flex: 1; */
}
.b11 {
  position:absolute;
  width:47%;
  height: v-bind(b10Height);
  display: flex;
  flex-direction: column;
  right:0;
  /* flex: 1; */
}
.b111 {
  position:relative;
  width:100%;
  height:100%;
}
.b12 {
  position:relative;
}
.b13 {      
  position:absolute;
  /* margin-top: v-bind(b12Height); */
  width:100%;
  height: v-bind(b13Height);
  
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  
}
.col1 {
  margin-top:60px;
  width: 28%;
  height:fit-content;
  display:block;
}
.row1 {
  width:100%;
  display:flex;
  justify-content: space-evenly;
}
.img1 {
  width:100%;  
  object-fit: cover;
  aspect-ratio: 1/1;
}
.img2, .img3 {    
  display:block;
  /* padding:40px; */
  height:85%;
  object-fit: cover; 
}
@media only screen and (max-width: 1152px) {
  .b4 {
    padding-top:60px;
    padding-bottom:30px;
  }  
  .b5 {
    padding-top:50px;
    padding-bottom: 50px;
  }
  .b6 {
    font-size: .85em !important;
    font-family:'font2';
    font-weight:1000;
  }
  .b71 {
    padding-top:50px;
    padding-bottom:50px;
  }
  .b8 {
    flex-direction: column;
    align-items: center;
  }
  .b9 {
    width:100%;
  }
  .b10, .b11 {
    width: 100%;
    position: relative;
  }
  .b13 {
    position: relative;
  }
  .b111 {
    margin-top:50px;
  }
  .col1 {
    width:45%;
  }
  .img2 {    
    height: auto;
    width:100%;
  }
  .img3 {
    position:relative;
  }
  .row1 {
    justify-content: space-between;
  }
}
</style>