<template>
  <router-view>    
  </router-view>  
</template>

<script>

// Color1: #eeedeb 
// Color2: #757263
// Color3: #e1ccbe

// Font-Family: granville H1
// Font-Family: brandon-grotesque H2
export default {  
  name: 'app',
  data() {
    return {
      showContact: false,
    }
  },
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Archivo+Narrow');  
@font-face {
    font-family: 'font1';
    src: url('~@/assets/fonts/granville-regular.otf');
  }
  @font-face {
    font-family: 'font2';
    src: url('~@/assets/fonts/BrandonGrotesque/brandon-grotesque-light.otf');
  }
  @font-face {
    font-family: 'font3';
    src: local(-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif);
  }
  .btn:hover {
    cursor:pointer;
    color: v-bind(color4);  
    text-decoration:underline;
    text-underline-offset: 3px;
  }
  .btn1 {
    background-color:black;
    height:2.7em;
    width:5.5em;
    color:v-bind(color1);
    display:flex;  
    align-items:center;
    justify-content: center;
    font-size: calc((1.2 - 1) * .1vw + 1rem);
  }
  .btn1:hover {
    cursor:pointer;
    background-color: v-bind(color4);
  }
  .btn2:hover {
    cursor:pointer;
  }
  .btn2 {
    display:flex;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
  }
  .space1 {
    width:30px;
  }
  .vspace1 {
    height:30px;
  }
  .space2 {
    width:60px;
  }
  .vspace2 {
    height:60px;
  }
  .t1 {
    font-family:'font1';
    font-size: calc(1.4vw + 1rem) !important;
  }
  .t2 {
    font-family: 'font1';
    font-size: calc(2.6 * 1.2vw + 1rem);
    font-weight:100;
  }
  .t3 {
    font-family: 'font2';
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  }
  .t4{
    font-family: 'font2';
    font-size: calc(.8 * 1vw + 1rem);
  }
  .t5 {
    font-family: 'font1';
    font-size: calc(2vw + 1rem);
  }
  .t6 {
    font-family: 'font1';
    font-size: calc(1.5vw + 1rem);
  }
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
html, body, #app {
  padding:0px;
  margin:0px;  
  width:100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}
@media only screen and (max-width: 1152px) {
  .t1 {
    font-size: calc(1.4vw + 1.2rem) !important;
  }
  .t4 {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    font-size: calc(1 * 1vw + 1.1rem);
    line-height:calc(2.6 * 1.2vw + 1.2rem);
  }
}
</style>
