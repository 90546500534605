<template>
  <Header></Header>
  <ContactForm></ContactForm>
  <Footer></Footer>
</template>

<script>
import Header from '../HeaderView.vue';
import Footer from '../FooterView.vue';
import ContactForm from '../../Utilities/ContactForm.vue';

export default {
  components: {    
    Header,
    Footer,
    ContactForm,
  },
  
}
</script>
