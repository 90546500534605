<template>
  <Header></Header>
  <div class="b1">
    <div class="b2">
      <div class="b3">
        <!-- <div class="t2">Services</div> -->
        <div class="b4">
          <div class="b17" v-for="f in folIndex" :key="f" ref="bHeight">            
            <div class="b5">
              <!-- <div class="b7" :class="[eventText===t1 && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="eventText=t1;showEvent=true"> -->
              <div class="b7" :class="[eventTitle===curFolders[(f-1)*2] && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="isCur=true; selectedFolder=(f-1)*2;selectedPhoto=0;eventTitle=curFolders[(f-1)*2];showEvent=true" >
                <div class="b6">
                  <img class="img1" :src="require('../../assets/Services' + getImagePath([(f-1)*2][0]))" />                  
                </div>
                <div class="t4">{{curFolders[(f-1)*2]}}</div>                
              </div>
            </div>            
            <div class="vspace5" v-if="curFolders[((f-1)*2)+1] != undefined && curFolders[((f-1)*2)+1]"></div>
            <div class="b5" v-if="curFolders[((f-1)*2)+1] != undefined && curFolders[((f-1)*2)+1]">              
              <div class="b7" :class="[eventTitle===curFolders[((f-1)*2)+1] && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="isCur=true; selectedFolder=((f-1)*2)+1;selectedPhoto=0;eventTitle=curFolders[((f-1)*2)+1];showEvent=true" >              
                <div class="b6">
                  <img class="img1" :src="require('../../assets/Services' + getImagePath([((f-1)*2)+1][0]))" />
                </div>
                <div class="t4">{{curFolders[((f-1)*2)+1]}}</div>
              </div>
            </div>
          </div>       
          <!-- <div class="b17">
            <div class="b5">              
              <div class="b7" :class="[serviceText===t1 && showService===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="serviceText=t1;showService=true">
                <div class="b6">
                  <img class="img1" src="../../assets/Services/1.png" />
                </div>
                <div class="t4">Resident Rehabilition and Support</div>
              </div>
            </div>
            <div class="vspace5"></div>
            <div class="b5">              
              <div :class="['b7','b12', serviceText===t2 && showService===true? 'b13' : '', !isMobile? 'b14':'']" @click="serviceText=t2;showService=true">
                <div class="b6">
                  <img class="img1" src="../../assets/Services/4.png" />
                </div>
                <div class="t4">Wellness Coordination</div>            
              </div>
            </div>          
          </div>
          <div class="b17">
            <div class="b5">              
              <div :class="['b7','b12', serviceText===t4 && showService===true? 'b13' : '', !isMobile? 'b14':'']" @click="serviceText=t3;showService=true">
                <div class="b6">
                  <img class="img1" src="../../assets/Services/3.png" />
                </div>
                <div class="t4">Respite</div>
              </div>
            </div> -->
            <!-- <div class="b5">
              <div class="vspace2"></div>
              <div :class="['b7','b12', serviceText===t4 && showService===true? 'b13' : '', !isMobile? 'b14':'']" @click="serviceText=t4;showService=true">
                <div class="b6">
                  <img class="img1" src="../../assets/Services/2.png" />
                </div>
                <div class="t4">Day Habilitation</div>                        
              </div>
            </div> -->
          <!-- </div> -->
        </div>
        <!-- <div class="b8" v-if="showService" >         
          <div class="b10" @click="showService=false"></div>     
          <div :class="['t4','b9']" >
            <div>{{ serviceText }}</div>
            <div class="vspace2"></div>
            <div class="b11">
              <div :class="['btn1']" @click="showService=false">Close</div>
            </div>
          </div>      
        </div> -->
        <div class="b8" v-if="showEvent" >         
          <div class="b10" @click="showEvent=false"></div>     
          <div :class="['t4','b9']" >                  
            <div class="t5">{{ eventTitle }}</div>
            <div class="b16" v-if="showImgs(selectedFolder) && !getImagePath1(selectedFolder, selectedPhoto).includes('logo.jpg')">
              <button class="leftButton" @click="leftClick">&lt;</button>
              <img class="img2" :src="require('../../assets/Services' + getImagePath1(selectedFolder, selectedPhoto))" />                
              <button class="rightButton" @click="rightClick">></button>                 
            </div>
            <div class="b19" v-if="isCur">
              <div :class="['t1','t12']" v-html="data[curFolders[selectedFolder]]['DESC']"></div>        
              <div :class="['t3','t13','t14']" v-html="data[curFolders[selectedFolder]]['DATETEXT']"></div>                              
              <div :class="['t3','t13']" v-html="data[curFolders[selectedFolder]]['DESC1']"></div>            
            </div>
            <div class="b19" v-else>           
              <div :class="['t1','t12']" v-html="data[passedFolders[selectedFolder]]['DESC']"></div>     
              <div :class="['t3','t13','t14']" v-html="data[passedFolders[selectedFolder]]['DATETEXT']"></div>        
              <div :class="['t3','t13']" v-html="data[passedFolders[selectedFolder]]['DESC1']"></div>            
            </div>
            <!-- <div class="vspace2"></div> -->
            <div class="b11">
              <div :class="['btn1']" v-if="isCur && data[curFolders[selectedFolder]]['REGISTER'] == 'POPUP'" @click="$refs.contactForm.showForm()">Register</div>
              <div :class="['btn1']" v-else-if="isCur && data[curFolders[selectedFolder]]['REGISTER'] != undefined" @click="openLink(data[curFolders[selectedFolder]]['REGISTER'])">Register</div>
              <div class="space1" v-if="isCur && data[curFolders[selectedFolder]]['REGISTER'] != undefined"></div>
              <div :class="['btn1']" @click="showEvent=false">Close</div>
            </div>
          </div>      
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '../HeaderView.vue';
import Footer from '../FooterView.vue';
import json from '../../assets/Services/data.json';
export default {
  name: 'services',
  components: {    
    Header,
    Footer,
  },
  data () {
    return {
      // showService: false,
      // serviceText: "",
      // t1: "Service definition: Residential Habilitation and Support (RHS) - Hourly services provide up to a full day (24-hour basis) of services and/or supports that are designed to ensure the health, safety, and welfare of the participant and assist in the acquisition, improvement, and retention of skills necessary to support participants to live successfully in their own homes.",
      // t2: "Day habilitation, which includes community-based habilitation and facility-based habilitation, are services specified in the Person-Centered Individualized Support Plan (PCISP) and support learning and assistance in the areas of: self-care, sensory/motor development, socialization, daily intended to build relationships and natural supports. Services are provided in a variety of settings in the community or in a facility owned or operated by an FSSA/DDRS-approved provider. Settings are non-residential and separate from a participant's private residence or other residential living arrangement.",
      // t3: "Service definition: According to the DDRS Waiver Manual: Respite care services are services provided to participants unable to care for themselves. Respite care services are furnished on a short-term basis in order to provide temporary relief to those unpaid persons normally providing care. Respite can be provided in the participant’s home or place of residence, in the respite caregiver’s home, in a camp setting, in a DDRS-approved day habilitation facility, or in a non-private residential setting (such as a respite home).",
      // t4: "Service definition: Wellness Coordination services means the development, maintenance, and routine monitoring of the waiver participant’s Wellness Coordination plan and the medical services required to manage his or her health care needs. Wellness Coordination services extend beyond those services provided through routine doctor/health care visits required under the Medicaid State Plan and are specifically designed for participants requiring assistance of an RN or LPN to properly coordinate their medical needs.",      
      // bHeight: 0,

      // events
      showEvent: false,
      eventText: "",      
      eventTitle: "",
      eventDates: "",
      bHeight: 0,
      images: [],
      pngs: [],
      folders: [],
      curFolders: [],
      passedFolders: [],
      curImages: [],
      passedImages: [],
      selectedFolder:0,
      selectedPhoto:0,
      data: json,
      eventData: {},
      passedData: {},
      isCur: true,
    }
  },
  computed: {
    folIndex() {
      return Math.ceil(this.curFolders.length/2);
    },
    passedFolIndex() {
      return Math.ceil(this.passedFolders.length/2);
    },
  },
  methods: {
      // handleResize() {
      //   console.log('resize');        
      //   if(this.$refs.bHeight === null){
      //     return;
      //   }        
      //   console.log(this.$refs.bHeight.clientHeight);
      //   this.bHeight = this.$refs.bHeight.clientHeight + 'px';
      // },
      // async resizeWait() {
      //   while(this.$refs.bHeight === undefined){
      //     await new Promise(resolve => setTimeout(resolve, 100));
      //   }
      //   console.log(this.$refs.bHeight.clientHeight);
      //   this.handleResize();
      // }
      handleResize() {
        console.log('resize');        
        if(this.$refs.bHeight === null || this.$refs.bHeight===undefined || this.$refs.bHeight.clientHeight === null || this.$refs.bHeight.clientHeight === undefined){
          return;
        }        
        console.log(this.$refs.bHeight.clientHeight);
        this.bHeight = this.$refs.bHeight.clientHeight + 'px';
      },
      async resizeWait() {
        while(this.$refs.bHeight === undefined){
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        console.log(this.$refs.bHeight.clientHeight);
        this.handleResize();
      },
      // Images
      importAll(r) {
        function sortAlphaNum(a,b) {
          return a.localeCompare(b, 'en', {numeric: true});
        }
        var imgs = []        
        var folder=""
        // var name=""
        var keys = r.keys();
        keys = keys.sort(sortAlphaNum);
        console.log(keys);
        keys.forEach(key=>(
            folder = key.substring(2),
            folder = folder.substring(0,folder.indexOf('/')),           
            imgs.push(folder)          
          ));      
        this.folders = [...new Set(imgs)];
        for(var i = 0; i < this.folders.length; i++){
          this.images.push(new Array());
        }
        keys.forEach((key)=>(
          console.log("key: " + key),
          folder = key.substring(2),
          folder = folder.substring(0,folder.indexOf('/')),   
          this.images[this.folders.indexOf(folder)].push(key)
        ))
        console.log(this.images);
      },
      importAllPngs(r) {
        function sortAlphaNum(a,b) {
          return a.localeCompare(b, 'en', {numeric: true});
        }
        var imgs = []        
        var folder=""
        // var name=""
        var keys = r.keys();
        keys = keys.sort(sortAlphaNum);
        console.log(keys);
        keys.forEach(key=>(
            folder = key.substring(2),
            folder = folder.substring(0,folder.indexOf('/')),           
            imgs.push(folder)          
          ));      
        this.folders = [...new Set(imgs)];
        for(var i = 0; i < this.folders.length; i++){
          this.pngs.push(new Array());
        }
        console.log("Folder Length: " + this.folders.length);
        console.log(keys);
        keys.forEach(key=>(
          console.log("key: " + key),
          folder = key.substring(2),
          folder = folder.substring(0,folder.indexOf('/')),   
          this.pngs[this.folders.indexOf(folder)].push(key)
        ))
        console.log(this.pngs);
      },
      getImagePath(index){
        if(this.pngs == undefined || this.pngs === null) {
          return "";
        }        
        return this.pngs[index][0].substring(1);
        // if(isCur){
        //   if(this.curImages === undefined || this.curImages === null){
        //     return "";
        //   }
        //   return this.curImages[index][0].substring(1);
        // } else {
        //   if(this.passedImages === undefined || this.passedImages === null){
        //     return "";
        //   }
        //   return this.passedImages[index][0].substring(1);
        // }
        
      },      
      getImagePath1(index,photo){
        if(this.isCur){          
          return this.curImages[index][photo].substring(1);
        } else {
          return this.passedImages[index][photo].substring(1);
        }
        // return this.images[index][photo].substring(1);
      },
      showImgs(index){
        console.log(this.curImages);
        if(this.curImages != undefined && this.curImages[index] != undefined && this.curImages[index].length > 0){
          return true;
        }
        return false;        
      },
      // selectFolder(index){
      //   this.selectedFolder=index;    
      // },
      // imageCount(index){
      //   return this.images[index].length;
      // },
      // imageIndex(index){
      //   return Math.ceil(this.images[index].length/3)
      // },
      // selectBack(){
      //   this.selectedFolder=-1;
      // },
      // photoSelected(index){      
      //   this.selectedPhoto=index;
      // },
      leftClick(){
        if(this.selectedPhoto===0){
          if(this.isCur){
            this.selectedPhoto=Object.keys((this.curImages[this.selectedFolder])).length-1;
          } else {
            this.selectedPhoto=Object.keys((this.passedImages[this.selectedFolder])).length-1;
          }      
        } else {
          this.selectedPhoto = this.selectedPhoto - 1;
        }
      },
      rightClick(){
        if(this.isCur){
          if(this.selectedPhoto===Object.keys((this.curImages[this.selectedFolder])).length-1){
            this.selectedPhoto=0;
          } else {
            this.selectedPhoto = this.selectedPhoto + 1;
          }
        } else {
          if(this.selectedPhoto===Object.keys((this.passedImages[this.selectedFolder])).length-1){
            this.selectedPhoto=0;
          } else {
            this.selectedPhoto = this.selectedPhoto + 1;
          }
        }
      },
      exitClick(){
        if(this.selectedPhoto != -1){
          this.selectedPhoto=-1;
        }
      },
    },
  mounted() {
    window.addEventListener('load',this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.importAllPngs(require.context("../../assets/Services/", true, /\.png$/));
    this.importAll(require.context("../../assets/Services/", true, /\.jpg$/));    
  },
  created() {
    this.serviceText = this.t1;    
    this.resizeWait();    
    window.scrollTo({
      top:0,
      left:0,
      behavior: 'smooth'
    });
    this.$nextTick(() => {      
      Object.keys(this.data).forEach((d) => {       
        console.log(d); 
        var date = new Date((this.data[d]["ENDDATE"]));      
        date.setDate(date.getDate() + 1);
        var today = new Date();                  
        if(today < date){          
          this.curImages.push(this.images[this.folders.indexOf(d)]);          
          this.curFolders.push(d);
        } else {
          this.passedImages.push(this.images[this.folders.indexOf(d)]);
          this.passedFolders.push(d);
        }                              
      });      
      console.log(this.curImages);
      console.log(this.passedImages);
    });
  },
  
}
</script>

<style scoped>
/* .b1 {
  width:100%;
  height: fit-content;      
}
.b2 {
 width:100%;
 background-color: v-bind(color2);
 padding-top:200px;
}
.b3 {
  width:100%;
  text-align:center;
}
.b4 {
  width:100%;
  background-image: url("../../assets/background/bg54.jpg");
  
  background-size: cover;
  background-position: center;
  padding-top:100px;
} */
/* .b5 {
  width:37%;
  display:flex;
  justify-content:space-evenly;
}
.b6 {
  width:100%;
  display:flex;
  justify-content:center;
}
.b7 {
  width:100%;
  padding:30px;
  border: 1px solid v-bind(color1);
  box-sizing: border-box;
  background-color: v-bind(color2);
  color:white;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.b8 {
  position: fixed;    
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items:center;
  z-index: 1;
}
.b9 {
  background-color: v-bind(color1);
  border-radius:5px;
  width:40%;
  padding: calc(1vw + 1rem);
  border: 1.5px solid v-bind(color6);
  box-shadow: 0 0 1px 5px v-bind(color6);
  z-index:3;
}
.b10 {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:2;
}
.b11 {
  width:100%;
  display:flex;
  justify-content:center;  
}
.b12 {
  height: v-bind(bHeight) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.b14:hover {
  border: 1px solid v-bind(color1);  
  border-radius: 5px;
  box-shadow: 0 0 5px 5px v-bind(color1);  
  cursor: pointer;  
  transform: scale(1.1);
}
.b13 {
  border: 1px solid v-bind(color1);  
  border-radius: 5px;
  box-shadow: 0 0 5px 5px v-bind(color1);  
  cursor: pointer;  
  transform: scale(1.1);
}
.b17 {
  width:100%;
  display:flex;
  justify-content: space-evenly;
  padding-bottom:100px;
}
.img1 {
  max-height:35vh;
  max-width:100%;
} */
.b1 {
  width:100%;
  height: fit-content;      
}
.b2 {
 width:100%;
 background-color: v-bind(color2);
 padding-top:200px;
}
.b3 {
  width:100%;
  text-align:center;
}
.b4 {
  width:100%;
  /* display:flex; */
  justify-content: space-evenly;
  padding-top:70px;
  /* padding-bottom:50px; */
  /* background-color: v-bind(color1); */
  /* background-image: url("../../assets/resources/background6.jpeg"); */
  background-image: url("../../assets/background/bg54.jpg");
  
  background-size: cover;
  background-position: center;
}

.b5 {
  /* margin-left:10%; */
  width:37%;
  display:flex;
  justify-content:space-evenly;
}
.b6 {
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:15px;
}
.b7 {
  width:100%;  
  padding:30px;
  border: 1px solid v-bind(color1);
  box-sizing: border-box;
  background-color: v-bind(color2);
  color:white;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.b8 {
  position: fixed;    
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items:center;
  z-index:100;
  /* z-index: 1; */
}
.b9 {
  display:block;
  background-color: v-bind(color1);
  border-radius:5px;
  width:60%;
  padding: calc(1vw + 1rem);
  border: 1.5px solid v-bind(color6);
  box-shadow: 0 0 1px 5px v-bind(color6);
  /* z-index:3; */
  overflow-y:auto;
  max-height:80vh;
  z-index:100 !important;
}
.b10 {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  /* z-index:2; */
}
.b11 {
  margin: 20px 0 0 0;
  width:100%;
  display:flex;
  justify-content:center;  
}
.b12 {
  height: v-bind(bHeight) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.b13 {
  border: 1px solid v-bind(color1);  
  border-radius: 5px;
  box-shadow: 0 0 5px 5px v-bind(color1);  
  cursor: pointer;  
  transform: scale(1.1);
}

.b14:hover {
  border: 1px solid v-bind(color1);  
  border-radius: 5px;
  box-shadow: 0 0 5px 5px v-bind(color1);  
  cursor: pointer;  
  transform: scale(1.1);
}
/* .b15 {
  position:fixed;
  max-width:90%;
  max-height:60vh;  
} */
.b16 {
  display:flex;
  align-items:center;
  justify-content: center;;
}
.b17 {
  width:100%;
  display:flex;  
  justify-content: space-evenly;
  padding-bottom:100px;
}
.b18 {
  color:white;
  /* color:black; */
  margin-bottom:50px;
}
.b19 {
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img1 {
  max-height:35vh;
  max-width:100%;
}
.img2 {
  max-width:85%;
  max-height:65vh;
  height:52vh;
  object-fit: contain;  
  margin: 30px 10px;
}
.rightButton {
  right:12%;
}
.leftButton {
  left:12%;
}
.leftButton,.rightButton {
  font-size:2em;
  color:black;
  /* position:absolute;   */
  background-color:grey;
  padding:2%;
  padding-right:1.5%;
  padding-left:1.5%;
  text-align:center;
  border-radius: 8%;  
  border:none;
  opacity:.5;
  z-index:100;
}
.leftButton:hover, .rightButton:hover {
  opacity:1;
  cursor:pointer;
}
.t12 {
  width:100%;
  margin-bottom:15px;  
}
.t13 {  
  width:40%;
  text-align:left;
  line-height: 1.7em;
}
.t14 {
  font-weight:500;
}
@media only screen and (max-width: 1152px) {
  /* .b2 {
    padding-top:120px;
  }
  .b4 {
    flex-direction: column;
    align-items: center;
    padding-top:70px;
  }
  .b5 {
    width:80%;
  }
  .b9 {
    width:72%;
    height:70vh;
    overflow-y: scroll;
  }
  .b17 {
    flex-direction: column;
    align-items: center;
    padding-bottom:70px;
  }
  .vspace5 {
    height:70px;
  } */
  .b2 {
    padding-top:120px;
  }
  .b4 {
    flex-direction: column;
    align-items: center;
  }
  .b5 {
    width:80%;
  }
  .b9 {
    width:80%;
    max-height:70vh;
    /* overflow-y: scroll; */
  }
  .b17 {
    flex-direction: column;
    align-items:center;
    padding-bottom:70px;
  }
  .img2 {
    max-width:80%;
    height:auto;
    margin: 30px 2px;
  }
  .vspace5 {
    width:100%;
    height:70px;
  }
  .t13 {
    width:80%;
  }
}
</style>
