<template>
  <div class="cimg1" >    
            <!-- <div class="leftBtn" @click="imgClick('left')">&lt;</div> -->
            <transition>
                <img class="cimg2" :key="imgIndex" :src="getImagePath()" />                
            </transition>
            <!-- <div class="rightBtn" @click="imgClick('right')">></div> -->
        </div>
</template>

<script>
  export default {  
    props: ['carouselFolder'], 
    data() {
      return {
        images: {},
        imgIndex: 0,
        timer1:'',  
        cf1: this.cf,
      }
    },
    methods: {
      getImagePath(){            
        // console.log(Object.keys(this.images)[0].substring(1))
        if(Object.keys(this.images).length > 0){
            return require("../assets/about-scroll" +  Object.keys(this.images)[this.imgIndex].substring(1));
        }
        return"";
      },
      importAll(r) {
          var imgs = {}     
          console.log(r);
          r.keys().forEach(key=>(imgs[key]=r(key)));
          this.images = imgs;                         
      },
      setTimer() {
          this.timer1=setInterval(() => {
              this.rightClick();
          },3000);
      },
      leftClick(){
          if(this.imgIndex === 0){
              this.imgIndex = Object.keys(this.images).length -1;
          } else {
              this.imgIndex = this.imgIndex - 1;
          }
      },
      rightClick(){
          if(this.imgIndex===(Object.keys(this.images).length-1)){
              this.imgIndex = 0;
          } else {
              this.imgIndex = this.imgIndex + 1;
          }
          setTimeout(() => {
              
          })
      },
    },
    mounted() {
      this.importAll(require.context("../assets/about-scroll/", true, /\.jpg$/));
    },
    created() {
        this.setTimer();
    }
  }
  
</script>

<style scoped>
  .cimg1{
    /* display:flex;    
    justify-content: center;
    align-items: center; */
    /* width:45%; */
    width:100%;
    position:relative;
    display:grid;
    grid-template-columns: 1fr;
  }
  .cimg2 {  
    width:100%;
    transition: all 1s ease;        
    grid-row-start:1;
    grid-column-start:1;
    position:relative;
    z-index:1;
  }
  .v-enter-active {
    opacity:0;
    z-index:10;
  }
  .v-leave-active {
      opacity:1;    
      z-index:1;
  }

  .v-enter, .v-leave-to {
      opacity:0;
  }
  .v-leave, .v-enter-to {
      opacity:1;
  }
</style>