<template>
  <ContactForm ref="contactForm"></ContactForm>
  <div class="hb2" v-click-out-side="backgroundClick">
    <div :class="[selected==='services'?'servicesCss':'','hb3']" >
      <div :class="['t1', 'btn', selected==='about'?'mt1':'']" @click="dispatch('home','home')">Full Potential</div>
      <div class="hb5" v-if="!isMobile">        
        <div :class="[selected==='home'?'navLine':'','t3','btn']" @click="dispatch('home','home')">Home</div>
        <div class="space1"></div>
        <div :class="[selected==='resources'?'navLine':'','t3','btn']" @click="dispatch('resources','resources')">Resources</div>
        <div class="space1"></div>
        <div :class="[selected==='about'?'navLine':'','t3','btn']" @click="dispatch('about','about'); ">About</div>
        <div class="space1"></div>
        <div :class="[selected==='services'?'navLine':'','t3','btn']" @click="dispatch('services','services'); ">Services</div>
        <div class="space1"></div>
        <div :class="[selected==='services'?'servicesCss':'','t3','btn1']" @click="$refs.contactForm.showForm()">Sign Up</div>
      </div>
      <div v-if="isMobile" class="menu1" v-click-outside="backgroundClick">           
                <img src="../assets/mobileMenu2.png" @click="openMenu"/>
                <transition>
                    <div class="menu2" v-if="showMenu" >                        
                            <ul class="menuList1">
                                <li :class="[selected==='home'?'styleRed1':'']" @click="dispatch('home','home'); closeMenu()">Home</li>                                
                                <div class="listLine"></div>
                                <li :class="[selected==='resources'?'styleRed1':'']" @click="dispatch('resources','resources'); closeMenu()">Resources</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='about'?'styleRed1':'']" @click="dispatch('about','about'); closeMenu()">About</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='services'?'styleRed1':'']" @click="dispatch('services','services'); closeMenu()">Services</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='signup'?'styleRed1':'']" @click="$refs.contactForm.showForm(); closeMenu()">Signup</li>
                                <div class="listLine"></div>                                
                            </ul>
                        <!-- </div> -->
                    </div>                    
                </transition>
        </div>   
    </div>
  </div>
</template>

<script>
import ContactForm from '../Utilities/ContactForm.vue';
import clickOutside from '@mahdikhashan/vue3-click-outside';

export default {
  name: 'header',
  directives: {
    clickOutside,
  },
  components: {
    ContactForm,
  },
  data() {
    return {
      selected: '',
      showMenu:false,
    }
  },
  methods: {
    openMenu() {
        this.showMenu=true;
    },
    closeMenu(){            
      this.showMenu=false;
    },
    // updateNav() {
    //   this.selected = 'signup';
    // }
  },
  mounted() {
    window.addEventListener('resize',this.windowSize);     
    // this.windowSize();
  },
  created() {
    this.$nextTick().then(() => {
      // this.$parent.$on('dispatch', (name, value) => {
      //   this.selected = value;
      // });
      this.selected=this.$parent.$options.name;
      console.log(this.margin1);           
    });
  },
}
</script>

<style scoped>
.hb2 {
  position:absolute;
  top:60px;  
  width:100%;
  font-weight:700;
  line-height:1;
  font-size: calc((1.5-1) * 1vw + 1rem);
  padding-bottom:calc(v-bind(margin1) * 1.5);
}
.hb3 {
  position:relative;
  display:flex;  
  margin-right:v-bind(margin1);
  margin-left:v-bind(margin1);
}
.hb5 {
  display:flex;
  position:absolute;
  right:0;  
  height:100%;
  align-items:center;
}
.mt1 {
      color:white !important;
    }
.navLine {
  text-decoration: underline;
  text-underline-offset: 3px;
}
.servicesCss {
  color: white !important;
}

@media only screen and (max-width: 1152px) {

  .hb2 {
    top:35px;
  }
  /*  MENU STYLE */
  .menu1 {
    position: fixed;
    top:0;
    right:0;
    display:block;        
    z-index:100;        
  }
  .menu1 > img {
    width:40px;
    height:auto;
    margin-top:25px;
    margin-right:v-bind(margin1);
    background-color:v-bind(color1);      
    /* padding:4px; */
    box-sizing: border-box;
    border-radius:4px;      
    padding:5px;
    box-sizing:border-box;
    /* box-shadow: 0 3px 6px rgba(68, 32, 32, 0.616), 0 0 5px rgb(127, 127, 127) inset; */
    box-shadow: 0 0 2px rgb(127, 127, 127);
    float:right;
  }
  .menu2 {
      position:relative;
      margin:0;
      /* padding:40px; */ 
      padding-top:85px;
      /* height:calc(100vh - 72px); */
      height:100vh;
      /* margin-top:-20px;     */
      /* right:-200px; */
      /* margin-right:-200px; */
      /* right:-20px; */
      transition: all .2s ease;
      margin-right:-20px;        
      background-color:v-bind(color2);
      color:v-bind(color3);
      /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
      font-family: font2;
      box-shadow: 0 3px 6px rgba(68, 32, 32, 0.616), 0 0 5px rgb(127, 127, 127) inset;
      /* font-weight:500 !important; */
      /* z-index:100; */
  }
  .menu2 > ul {
    margin:0;
    padding:0;
    list-style-type: none;
  }
  .menu2 >ul > li,.subMenu1 > ul > li {
    width:100%;
    height:40px;
    width:120px;
    padding-left:35px;
    display:flex;
    align-items:center;
  }    
  .subMenu1 > ul > li {
      padding-left:15px;
      padding-right:15px;
  }
  .subMenu1 > ul > li:active {
      background-color:v-bind(color21);
  }
  .subMenu3 {
      width:10px!important;
      height:40px;
      background-color:v-bind(color21);
  }
  .styleRed1 {
      background-color:v-bind(color21); 
  }
  .listLine {        
      /* width:80%;
      margin-left:10%; */
      width:100%;
      border-top:1px solid rgba(131, 131, 131, 0.532);
  }
  .subMenu3 {
      position:absolute;
      right:155px;
      background-color:rgb(110, 55, 55);  
  }
  .subMenu1 {
      position:absolute;
      right:162px;
      background-color:rgb(110, 55, 55);  
  }
  .subMenu1 > ul {
      padding: 0;
      margin: 0;
  }
  .v-enter-active {
      transform: translateX(200px);
  }
  .v-leave-active {
  }

  .v-enter, .v-leave-to {
      opacity:1;
      transform: translateX(200px);
  }
  .v-leave, .v-enter-to {
      /* margin-right:-20px; */
  }
  @media only screen and (max-width: 1152px) {
    .mt1 {
      color:white !important;
    }
  }
}
</style>